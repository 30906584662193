.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-container{
  margin: 3em;
}

.react-multiple-carousel__arrow--left::before {
  content: "\e824";
  color: #476ebc;
  z-index: 45;
}

.react-multiple-carousel__arrow--left {
  left: -15px;
  z-index: 45;
}

.react-multiple-carousel__arrow--right::before {
  content: "\e825";
  color: #476ebc;
  z-index: 45;
}

.react-multiple-carousel__arrow--right {
  right: -15px;
  z-index: 45;
}

.react-multiple-carousel__arrow--right:hover {
  background-color: #d7d7d700;
}

.react-multiple-carousel__arrow--left:hover {
  background-color: #d7d7d700;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-item:last-of-type {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-button:not(.collapsed) {
  color: #fff;
  background-color: #e7f1ff00;
  box-shadow: none;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe00; 
  outline: 0;
  box-shadow: none;
}

.accordion-button::after {
  height: 1.5rem;
}

.accordion-button.collapsed::after {
  background-image: url("../src/assets/images/accord_arrow_down.png");
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../src/assets/images/accord_arrow_down.png");
}

#our_work{
  background-image: url("../src/assets/images/our_work_2_ext.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: '100%';
}

.grecaptcha-badge { visibility: hidden; }

.dropdown-item {
  padding: 0 .5rem;
}

.dropdown-menu {
  padding: 0;
}

.btn{
  padding: 0;
  border: none;
}

.show>.btn-light.dropdown-toggle:focus {
  box-shadow: none;
  background-color: #ffffff00;
}

.carousel-container {
  margin: 3em 0;
}

.accordion-button {
  padding: .75rem;
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 1rem !important;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}